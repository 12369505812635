<template>
    <div class="out">
        <div class="cw_title">
            {{$t('main.robotAddTip')}}
           <div class="cw_text">{{$t('main.wxrobot')}}</div>
            
        </div>
        <div class="qrcode">
            <img :src="pic_url" alt="">
        </div>
        <div class="robot_text">
            {{$t('main.robotAdd')}}
        </div>
        <div class="robot_button" @click="show_pop">{{$t('main.fitcira')}}</div>
        <div class="mst_text">
            {{ $t('main.ccc2') }}
        </div>
		<div class="xwt">
			{{$t('main.lefof')}}
		</div>
        <div class="icon_list">
			
			<div class="icon_flex">
				
				<img src="../assets/img/ydzc_r.png" alt="">
				<img src="../assets/img/logo-icon.png" alt="">
				<img src="../assets/img/ccwl_r.png" alt="">
				<img src="../assets/img/ccwc_r.png" alt="" >
				
			</div>
			<div class="text">
				<span style="color:#4b4b4b">{{$t('main.copyacon')}}<br></span>
{{$t('main.tppswsc')}}<br>
{{$t('main.platTips')}}<br>
<span style="color:#4b4b4b" >{{$t('main.noaldar')}}</span>
				</div>
		</div>
        <div class="pop" v-show="pop_to" @click="show_pop">
			<div class="pop-in" @click.stop>
				<div class="green_text">{{$t('main.apptocoll')}}</div>
				<div class="black_text">请输入您的资料，会有专人联系您 </div>
				<div>
					<div class="input_list">
						<span>{{$t('main.fullname')}}:</span>
						<div>
							<input type="text" v-model="name">
						</div>
					</div>
					<div class="input_list">
						<span>{{$t('main.tow')}}</span>
						<div>
							<input type="text" v-model="type" :placeholder="$t('main.fewc')">
						</div>
					</div>
					<div class="input_list">
						<span>{{$t('main.contactInfo')}}</span>
						<div>
							<input type="text" v-model="phone">
						</div>
					</div>
				</div>
				<div class="button_list">
					<div  @click="show_pop">{{$t('main.cancel')}}</div>
					<div @click="upload_master">{{$t('main.comfirm')}}</div>
				</div>
			</div>
		</div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            pic_url:'',
            pop_to:false,
				name:'',
				type:'',
				phone:''
        }
    },
    mounted(){
        this.axios.post('/common/getConfig').then((res)=>{
            
            this.pic_url = res.data.data.artist_qr_code.replace(/((^[A-Za-z]{4,5})+\:)/,location.protocol)
        })
    },
    methods:{
        show_pop(){
				this.pop_to = !this.pop_to
			},
			upload_master(){
				
				if(!this.name||!this.type||!this.phone){
					weui.alert(this.$t('main.pftci'),{buttons:[{label:this.$t('main.confIrm')}]})
				}else{
					let last_time = localStorage.getItem('Collector_Time')||0
					if(parseInt(last_time)>new Date().getTime()){
						
						(this.$t('main.yhsyapt'),{buttons:[{label:this.$t('main.confIrm')}]})
						return
					}
					this.axios.post('/artist/apply',{type:2,name:this.name,phone:this.phone,content:this.type}).then((res)=>{
						if(res.data.code == 0){
							let time =  new Date().getTime() + 60*60 *1000
							localStorage.setItem('Collector_Time',time)
							weui.alert(this.$t('main.appsusuccplta'),{buttons:[{label:this.$t('main.confIrm'),onClick:()=>{
								this.show_pop()
							}}]})

						}else{
							weui.alert(this.$t('main.appsufaplta'),{buttons:[{label:this.$t('main.confIrm')}]})
						}
					})
				}
			}
    }
}
</script>

<style scoped>
.out{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 0.18rem;
    color: #000;
    box-sizing: border-box;
	background: url(../assets/img/index_bg.png) no-repeat center;
	background-size: cover;
    padding-top: 0.29rem;
}
.cw_title{
	font-weight: 600;
    position: relative;
    
}
.cw_title::before{
    content: '';
    display: block; position: absolute;
    left: -0.3rem;
    top: 0.08rem;
    background: url(../assets/img/bjgw.png) no-repeat;
    background-size: cover;
    width: 0.17rem;
    height: 0.13rem;
}
.cw_title::after{
    content: '';
    display: block; position: absolute;
    right: -0.3rem;
    top: 0.08rem;
    background: url(../assets/img/bjgw.png) no-repeat;
    background-size: cover;
    width: 0.17rem;
    height: 0.13rem;
    transform: rotateY(180deg);
}
.cw_text{
    margin-top: 0.12rem;
    margin-bottom: 0.16rem;
}
.robot_text{
    font-size: 0.12rem;
    color: #636363;
    margin: 0.225rem 0;
}
.green_text{
		color:#1b6a21;
		font-size: 0.18rem;
		margin-bottom: 0.15rem;
	}
    .black_text{
		font-size: 0.12rem;
		margin-bottom: 0.15rem;
	}
.robot_button{
    width: 2.65rem;
    height: 0.35rem;
    background: #9a2a25;
    color: #fff;
    border-radius: 0.06rem;
    text-align: center;
    line-height: 0.35rem;
    font-size: 0.14rem;
    cursor: pointer;
}
.mst_text{
	color: #171717;
    margin-top: 0.33rem;
    font-size: 0.11rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.mst_text div{
    position: relative;
    line-height: 0.15rem;
}
.mst_text div::before{
    content: '';
    display: block; position: absolute;
    left: -0.28rem;
    top: 0rem;
    background: url(../assets/img/zgj.png) no-repeat;
    background-size: cover;
    width: 0.08rem;
    height: 0.15rem;
}
.mst_text div::after{
    content: '';
    display: block; position: absolute;
    right: -0.28rem;
    top: 0rem;
    background: url(../assets/img/zgj.png) no-repeat;
    background-size: cover;
    width: 0.08rem;
    height: 0.15rem;
    transform: rotateY(180deg);
}
.xwt{
	margin-top: 0.225rem;
	position: relative;
	color: #9a2a25;
	font-size: 0.15rem;
	line-height: 0.15rem;
	font-weight: 600;
}
.xwt::before{
    content: '';
    display: block; position: absolute;
    left: -0.28rem;
    top: 0rem;
    background: url(../assets/img/xwt.png) no-repeat;
    background-size: 100%;
	background-position: 0 center;
	width: 0.145rem;
    height: 0.15rem;
}
.xwt::after{
    content: '';
    display: block; position: absolute;
    right: -0.28rem;
    top: 0rem;
    background: url(../assets/img/xwt.png) no-repeat;
    background-size: 100%;
	background-position: 0 center;
    width: 0.145rem;
    height: 0.15rem;
    transform: rotateY(180deg);
}
.mst_text div:nth-child(2){
    margin-top: 0.09rem;
}
.qrcode{
    width:2.07rem;
    height: 2.15rem;
    padding: 0.14rem 0.1rem;
    background: url(../assets/img/redrect.png) no-repeat;
    background-size: 100% 100%;
}
.qrcode img {
    width: 100%;
    height: 100%;
    /* padding: 0.14rem;
    background: #fff; */
    box-sizing: border-box;
}
.icon_list{
    margin-top: 0.4rem;
}
.icon_flex{
    width: 100%;
    box-sizing: border-box;
    padding: 0 0.28rem;
    display: flex;
    justify-content: space-around;
}
.input_list{
		display: flex;
		align-items: center;
		margin-bottom: 0.1rem;
	}
	.input_list>span{
		white-space: nowrap;
		margin-right: 0.05rem;
		flex-shrink: 0;
	}
	.input_list>div:nth-child(2){
		flex: 1;
		box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
		border-radius: 6px;
		height: 0.4rem;
		display: flex;
	}
	.input_list input{
		flex: 1;
		outline: none;
		border-radius: 6px;
		border: none;
		padding-left: 0.1rem;
		box-sizing: border-box;
		height: 0.4rem;
		line-height: 0.4rem;
		font-size: 0.13rem;
	}
.icon_flex img{
    height: 0.2rem;
    object-fit: contain;
}
.text{
    margin-top: 0.17rem;
    font-size: 0.11rem;
    color: #171717;
    padding-bottom: 0.34rem;
}
.pop{
		position: fixed;
		width: 100%;
		height: 100vh;
		background: rgba(0, 0, 0, 0.5);
		top: 0;
		left: 0;
	}
	.pop .pop-in{
		position: absolute;
		top: 50%;
		left: 50%;
		width: 3.45rem;
		box-sizing: border-box;
		padding: 0.15rem;
		transform: translate(-50%,-50%);
		background: #FFFFFF;
		border-radius: 0.08rem;
		font-size: 0.15rem;
	}
    .button_list{
		margin-top: 0.3rem;
		display: flex;
		justify-content: space-between;
	}
	.button_list div{
		width: 1.45rem;
		height: 0.5rem;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		color: #fff;
		font-size: 0.18rem;
		border-radius: 0.05rem;
	}
	.button_list>div:nth-child(1){
		background: #535353;
	}
	.button_list>div:nth-child(2){
		background: #2d71d7;
	}

</style>